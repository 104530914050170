<template>
  <div class="view-container">
      <profile-form/>
  </div>
</template>

<script>
import ProfileForm from '../../components/Forms/ProfileForm.vue'
export default {
  components: { ProfileForm },
    name: "profile"

}
</script>

<style lang="scss" scoped>

</style>