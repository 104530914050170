<template>
  <div class="form-group">
    <label :for="id" class="custom-radio">
      <input
        :id="id"
        :name="name"
        :value="value"
        @change="updateValue"
        class="custom-radio__input"
        type="radio"
        tabindex="0"
        :checked="modelValue == value"
        :disabled="disabled"
      />
      <span class="custom-radio__label">
        {{ label }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "custom-radio",
  props: {
    modelValue: {
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const updateValue = (event) => {
      context.emit("update:modelValue", event.target.value);
    };

    return {
      updateValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  justify-content: flex-start;
  .custom-radio {
    min-height: 22px;
    min-width: 22px;
    margin-bottom: 12px;
    margin-top: 6px;
    position: relative;
    display: block;
    padding-left: 38px;
    cursor: pointer;

    &__input {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    &__input:checked ~ .custom-radio__label::before {
      border-color: #2cc185;
      background-color: #fff;
      color: #2cc185;
    }

    &__input:checked ~ .custom-radio__label::after {
      content: "";
      border-radius: 50%;
      height: 26px;
      width: 26px;
      top: calc(50% - 13.5px);
      left: 0;
      background-color: #2cc185;
    }

    &__input:hover ~ .custom-radio__label::before {
      border-color: var(--main-color);
    }

    &__input:focus ~ .custom-radio__label::before {
      border-color: var(--main-color);
    }

    &__label {
      position: unset;
      white-space: break-spaces;
      word-break: break-word;
      font-size: 14px;

      &::before,
      &::after {
        left: 0;
        transition: border-color 0.3s ease;
      }

      &::before {
        border: 2px solid #ebebeb;
        content: "";
        position: absolute;
        top: calc(50% - 13.5px);
        display: block;
        width: 27px;
        height: 27px;
        pointer-events: none;
        background-color: #fff;
        border-radius: 50%;
      }

      &::after {
        position: absolute;
        top: calc(50% - 13.5px);
        display: block;
        width: 27px;
        height: 27px;
        content: "";
        background: no-repeat 50%/50% 50%;
      }
    }
  }
}
</style>