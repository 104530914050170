<template>
  <div class="profile-form display--flex">
    <div class="card">
      <div class="card-header card-header-divider">Профиль</div>
      <div class="card-body">
        <form action="#" @submit.prevent="submitProfile" novalidate>
          <div
            class="profile-form__inputs display--flex justify-content-center"
          >
            <div class="profile-form__col">
              <custom-input
                v-model="form.last_name"
                label="Фамилия"
                name="last_name"
                req
              />
              <custom-input
                v-model="form.first_name"
                label="Имя"
                name="first_name"
                req
              />
              <custom-input
                v-model="form.patronymic"
                label="Отчество"
                name="patronymic"
                req
              />
              <custom-input
                v-model="form.age"
                label="Возраст"
                name="age"
                type="number"
                req
                :maxValue="100"
                :minValue="1"
              />
              <div class="profile-form__radios flex justify-content-center">
                <custom-radio
                  v-model="form.sex"
                  id="radio-male"
                  label="Мужчина"
                  name="sex"
                  value="male"
                />
                <custom-radio
                  v-model="form.sex"
                  id="radio-female"
                  label="Женщина"
                  name="sex"
                  value="female"
                />
              </div>
              <custom-input
                v-model="form.email"
                name="email"
                label="Email"
                type="email"
                req
              />
              <custom-input
                v-model="form.password"
                name="password"
                label="Изменить пароль"
                type="password"
                req
              />
            </div>
            <div class="profile-form__col">
              <preview-file-loader
                @loadPhoto="
                  (data) => {
                    form.image = data;
                    form.currentImage = null;
                  }
                "
                @delete="
                  () => {
                    form.currentImage = null;
                    form.image = null;
                  }
                "
                :image="form.currentImage ? form.currentImage : form.image"
                class="profile-form__photo-area"
                label="Фото"
                name="image"
              />
              <custom-select-2
                v-model="form.profession_id"
                label="Проф. деятельность"
                name="profession_id"
                :options="professionOptions"
              />
              <custom-select-2
                v-model="form.industry_id"
                label="Отрасль"
                name="industry_id"
                :options="industriesOptions"
              />
              <custom-select-2
                v-model="form.position_id"
                label="Уровень должности"
                name="position_id"
                :options="positionOptions"
              />
              <city-input
                :city="form.city"
                @changeCity="(cityId) => (form.city_id = cityId)"
              />
            </div>
          </div>
          <div
            class="
              profile-form__btns
              mt-25
              mb-10
              display--flex
              justify-content-end
            "
          >
            <button type="submit" class="btn btn-accent mr-10">
              Сохранить
            </button>
            <button type="button" @click="router.go(-1)" class="btn btn-w">
              Назад
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CustomInput from "./Fields/CustomInput.vue";
import { useStore } from "vuex";
import { reactive, ref } from "@vue/reactivity";
import PreviewFileLoader from "./Fields/PreviewFileLoader.vue";
import { computed, onBeforeMount } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import standartError from "../../mixins/standartError";
import CustomRadio from "./Fields/CustomRadio.vue";
import CustomSelect2 from "./Fields/CustomSelect2.vue";
import useErrors from "../../mixins/useErrors";
import useProfileForm from "../../mixins/useProfileForm";
import { notify } from "@kyvg/vue3-notification";
import CityInput from "./Fields/CityInput.vue";

export default {
  components: {
    CustomInput,
    PreviewFileLoader,
    CustomRadio,
    CustomSelect2,
    CityInput,
  },
  name: "profile-form",
  setup() {
    const store = useStore(),
      router = useRouter(),
      { setErrors, clearCustomErrors } = useErrors(),
      { setForm } = useProfileForm();
    const profile = computed(() => store.state.profile.profile),
      positionList = computed(() => store.state.test.positionList),
      industriesList =  computed(() => store.state.test.industriesList),
      professionList = computed(() => store.state.test.professionList);
    const form = reactive({
      last_name: null,
      first_name: null,
      patronymic: null,
      age: null,
      sex: null,
      profession_id: null,
      industry_id: null,
      position_id: null,
      city_id: null,
      image: null,
      email: null,
      password: null,
      currentImage: null,
      city: null,
    });
    const showCityEdit = ref(false);

    const professionOptions = ref([]),
        industriesOptions = ref([]),
      positionOptions = ref([]),
      citySettings = ref({
        minimumInputLength: 3,
        delay: 500,
        ajax: {
          url: function (params) {
            return `${process.env.VUE_APP_BACK}/api/addresses/${params.term}`;
          },
          beforeSend: function (request) {
            request.setRequestHeader(
              "Authorization",
              `Bearer ${profile.value.token}`
            );
          },
          processResults: function (data) {
            const results = [];
            for (const [key, value] of Object.entries(data)) {
              results.push({ id: key, text: value });
            }
            return {
              results,
            };
          },
          cache: true,
        },
      });

    onBeforeMount(() => {
      store.dispatch("profile/getProfile").then(() => {
        setForm(profile.value, form);
      });

      store.dispatch("test/getPositionList").then(() => {
        positionOptions.value = positionList.value.map((position) => {
          return { id: position.id, text: position.text };
        });
      });
      store.dispatch("test/getProfessionList").then(() => {
        professionOptions.value = professionList.value.map((profession) => {
          return { id: profession.id, text: profession.text };
        });
      });
      store.dispatch("test/getIndustriesList").then(() => {
        industriesOptions.value = industriesList.value.map((industry) => {
          return { id: industry.id, text: industry.text };
        });
      });
    });

    const submitProfile = () => {
      if (form.age && (form.age <= 0 || form.age > 100)) {
        notify({
          type: "error",
          title: "Возраст должен быть числом от 1 до 100",
        });
        return;
      }
      store
        .dispatch("profile/updateProfile", {
          ...form,
          id: profile.value.id,
          company_id: profile.value.company ? profile.value.company.id : null,
        })
        .then(() => {
          form.password = null;
          setForm(profile.value, form);
        })
        .catch(standartError(setErrors, clearCustomErrors));
    };

    return {
      form,
      submitProfile,
      profile,
      professionOptions,
      citySettings,
      positionOptions,
      router,
      showCityEdit,
      industriesOptions
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-form {
  display: flex;
  margin: auto;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 769px) {
    .form-group {
      margin: 25px -5px;
    }
  }

  &__radios {
    margin-top: 25px;
    .form-group:not(:last-child) {
      margin-right: 2rem;
    }
  }

  &__organization {
    flex: 0 1 20%;
    padding: 25px 40px 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__title {
    display: block;
    font-size: 26px;
    padding-bottom: 10px;
  }

  &__text {
    font-size: 27px;
    display: block;
    padding-bottom: 10px;
    max-width: 90%;
  }

  &__col {
    flex: 0 1 50%;
    padding: 0 10px;
  }

  &__city {
    padding-left: 10px;

    div {
      display: inline-block;
      font-size: 15px;
      flex: 0 0 25%;
      font-weight: 400;
      margin: 33px 0;
    }
    span {
      margin: 33px 0;
    }
    button:hover i {
      color: var(--main-color);
    }
    button {
      margin-left: 10px;
    }
  }

  &__edit-city {
    .form-group {
      flex: 1 0 auto;
      max-width: 92%;
    }

    button {
      height: 20px;
      width: 20px;
      margin-left: 10px;
      img {
        max-width: 100%;
      }
    }
  }

  @media (max-width: 1360px) {
    width: 100%;
  }

  @media (max-width: 1500px) {
    &__inputs {
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 425px) {
    &__photo-area {
      margin: 0 !important;
    }
  }
}
</style>